'use client'

import type { ReactNode } from 'react'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { getCookie } from 'cookies-next'

import { publicConfig } from '~/config'

if (typeof window !== 'undefined') {
	const flags = getCookie('bootstrapData')

	const bootstrapData = (flags ? JSON.parse(flags.toString()) : {}) as Record<string, unknown>

	posthog.init(publicConfig.posthog.key, {
		api_host: `${publicConfig.app.host}/ingest`,
		ui_host: 'https://app.posthog.com',
		bootstrap: bootstrapData,
		person_profiles: 'always',
	})
}

const PHProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	return (
		<PostHogProvider client={posthog}>
			{children}
		</PostHogProvider>
	)
}

export default PHProvider
